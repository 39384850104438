import React from "react"
import Img from 'gatsby-image'

const ImageCard = ({ card }) => (
    <div className={`grid grid-cols-1 place-items-center w-full mt-three`}>


                <Img className={`mb-o  ${card?.rounded !== null && card?.rounded == false ? 'w-thirty xl:w-twen h-fifteen xl:h-ten' : 'rounded-full w-twen xl:w-ten h-fifteen xl:h-ten' }`} fluid={card.image} /> 

                <p className="font-semibold">{card.community}</p> 

                <p className="italic text-quaternary">{card.website}</p>
                                
        
    </div>
)

export default ImageCard; 